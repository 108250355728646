import React from 'react';
import AnalyticsService from '../analytics/analytics-service';
import {Link} from 'react-router-dom';
import {ReactComponent as QRCodeImage} from '../images/qr_code.svg';
import {FormattedMessage} from 'react-intl';

function TopBanner(): React.ReactElement {
    return (
        <div className='d-md-flex align-content-center align-items-center flex-md-row-reverse py-5 px-md-5 text-center'
             style={{maxWidth: '1300px'}}>
            <div className='col-6 col-md-4 col-lg-3 mx-auto px-2'>
                <QRCodeImage title='QR code' />
            </div>
            <div className='col-12 col-md-8 col-lg-9 mx-auto my-5 px-2'>
                <h1 className='display-5 font-weight-bold py-4'>
                    <FormattedMessage id="Landing.TopBanner.title" />
                </h1>
                <p className='lead font-weight-normal px-4'>
                    <FormattedMessage id="Landing.TopBanner.mainText" />
                </p>
                <Link className='btn btn-primary my-5 p-2 px-3' to='/new' onClick={AnalyticsService.shared().trackLandingPageTopAddNewMenuButtonClick}>
                    <FormattedMessage id="Landing.Banner.Button.title" />
                </Link>
            </div>
        </div>
    );
}

export default TopBanner;
