import React, {ReactElement} from 'react';

export interface FeatureProps {
    header: ReactElement;
    content: ReactElement;
    img: string;
    reverse?: boolean;
}

class Feature extends React.Component<FeatureProps, {}> {

    render(): ReactElement {
        const {header, content, img, reverse} = this.props;
        return (
            <div
                className={'d-md-flex align-content-center align-items-center justify-content-between pb-5 text-center' + (reverse ? ' flex-md-row-reverse text-md-left' : ' text-md-right')}>
                <div className='col-12 col-md-6'>
                    <div className='col-9 col-md-12 mx-auto'>
                        <img src={img} alt={img} className='img-fluid rounded w-100'/>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='my-3 py-3 py-md-5'>
                        <h2 className='display-5'>{header}</h2>
                        <p className='lead'>{content}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Feature;
