import React, {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import AnalyticsService from '../analytics/analytics-service';
import {FormattedMessage} from 'react-intl';

function BottomBanner(): ReactElement {
    return (
        <div className='d-md-flex flex-md-equal pb-5 text-center'>
            <div className='col-md-12 mx-auto my-5'>
                <h5 className='display-5 mb-4'>
                    <FormattedMessage id="Landing.BottomBanner.mainText" />
                </h5>
                <Link to='/new' className='btn btn-primary my-2 p2 px-3' onClick={AnalyticsService.shared().trackLandingPageBottomAddNewMenuButtonClick}>
                    <FormattedMessage id="Landing.Banner.Button.title" />
                </Link>
            </div>
        </div>
    );
}

export default BottomBanner;
