import React, {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';

import DefaultLayout from '../../layout/default';
import TopBanner from '../../components/top-banner';
import BottomBanner from '../../components/bottom-banner';
import Feature, {FeatureProps} from '../../components/feature';

import feature1Image from '../../images/feature_1_image.svg';
import feature2Image from '../../images/feature_2_image.svg';
import feature3Image from '../../images/feature_3_image.svg';
import feature4Image from '../../images/feature_4_image.svg';
import feature5Image from '../../images/feature_5_image.svg';

const Features: Array<FeatureProps> = [
    {
        header: <FormattedMessage id="Landing.Feature1.title" />,
        content: <FormattedMessage id="Landing.Feature1.mainText" />,
        img: feature1Image
    },
    {
        header: <FormattedMessage id="Landing.Feature2.title" />,
        content: <FormattedMessage id="Landing.Feature2.mainText" />,
        img: feature2Image
    },
    {
        header: <FormattedMessage id="Landing.Feature3.title" />,
        content: <FormattedMessage id="Landing.Feature3.mainText" />,
        img: feature3Image
    },
    {
        header: <FormattedMessage id="Landing.Feature4.title" />,
        content: <FormattedMessage id="Landing.Feature4.mainText" />,
        img: feature4Image
    },
    {
        header: <FormattedMessage id="Landing.Feature5.title" />,
        content: <FormattedMessage id="Landing.Feature5.mainText" />,
        img: feature5Image
    }
];

function Landing(): ReactElement {
    return (
        <DefaultLayout displayHeaderButtons={true}>
            <div className='pt-lg-5'>
                <TopBanner/>
                <div className='col-lg-11 mx-auto'>
                    {Features.map((feature, idx) =>
                        <Feature key={feature.img}
                                 header={feature.header}
                                 content={feature.content}
                                 img={feature.img}
                                 reverse={idx % 2 === 0}/>)}
                </div>
                <BottomBanner/>
            </div>
        </DefaultLayout>
    );
}

export default Landing;
